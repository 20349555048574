import Loadable from 'react-loadable'; 
import { Image, Space} from 'antd' 
import Loading from '../components/Loading';

const lazyLoadRoutes = (componentName) => {
    const LazyComponent = Loadable({
        loader: () => import(`../pages/${componentName}`),
        loading: () => <div style={{ position: 'absolute', top: '45%', left: '30%', fontSize: 20 }}>
        <div>
          <div style={{ marginLeft: '40%', marginBottom: 15 }}>
            <Image
              width={40}
              src="../assets/message-bot-solid.png"
              preview={false}
            />
          </div>
          <Space>
            <h4>
              Just a moment, we are getting things ready for you
            </h4>
            <Loading style={{ width: 100, height: 30, margin: '5px 0px' }} />
          </Space>
        </div>

      </div>
    })

    return LazyComponent;
}

export default lazyLoadRoutes