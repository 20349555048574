const initialState = {
    cacheId: 0,
    faqMenuItems: [],
    faqHistory: []
}

const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CACHEID":
            return {
                ...state,
                cacheId: action.payload,
                faqHistory: []
            };
        case "GET_FAQS":
            return {
                ...state,
                faqMenuItems: action.payload
            };
        case "GET_FAQHISTORY":
            return {
                ...state,
                faqHistory: [action.payload],
                cacheId: action.payload.cacheID
            };

        case "SEND_FOLLOWUP_QUESTION":
            const request = action.payload.req;
            const response = action.payload.res;

            if (request.status === 'P') {
                return {
                    ...state,
                    faqHistory: [...state.faqHistory, action.payload.req]
                };
            }
            else {
                const index = state.faqHistory.findIndex(x => x.key === request.key);
                state.faqHistory[index] = response;

                return {
                    ...state
                };
            }
        case "RESET_STREAM":
            const updatedFaqHistory = state.faqHistory.map(item => ({ ...item, is_stream: false }));
            return {
                ...state,
                faqHistory: updatedFaqHistory
            };

        case "UPDATE_FAQ_THUMB":
            let data = state.faqHistory.find(x => x.message_id === (action.payload.id === null ? null : action.payload.msgId));
            data.thumb_feedback = action.payload.value;
            data.message_id = action.payload.msgId
            return {
                ...state,
            };

        default:
            return state;
    }
}


export default faqReducer