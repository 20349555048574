const initialState = {
    isFAQ: false,
    environmentId: null,
    deviceType: ''
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ISFAQ":
            return {
                ...state,
                isFAQ: action.payload
            };

        case "SET_ENVIRONMENTID":
            return {
                ...state,
                environmentId: action.payload
            };

        case "SET_DEVICE":
            return {
                ...state,
                deviceType: action.payload
            };
        default:
            return state;
    }
}


export default commonReducer