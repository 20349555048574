import { combineReducers } from 'redux'
import auth from './authReducer'
import chat from './chatReducer'
import faq from './faqReducer'
import common from './commonReducer'

export default combineReducers({
    auth,
    chat,
    faq,
    common
})