import React from 'react'
import { useMsal } from "@azure/msal-react";
import { Button, Flex, Space, Typography } from 'antd';

const { Text } = Typography;

const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <a onClick={() => handleLogout("popup")}>
            <Space size={[5, 0]} style={{display:'flex', alignItems:'center'}}>
                <i className="bx bx-log-out" style={{ fontSize: 18, paddingTop: 20 }} />
                <Text style={{ color: '#fff' }}>Sign out</Text>
            </Space>
        </a>
    )
}

export default SignOutButton